#downloads {
    
    .download-container {
        padding:15px;
        background:#eee;
        font-size:16px;
    }
    
    .date-info {
        font-size:13px;
        padding-top:5px;
        color:#666;
    }
    
    .download-options a {

        span {
            margin-left:20px;
            display: block;
        }

        .icon {
            text-align:center;
        }

    }
    
    .download-option {
        font-size:12px;
    }
    
    .download-generating {
        color:#666;
        font-weight:bold;
        font-style:italic;
        padding-top:5px;
        font-size:13px;
    }

    input {
        &.download-input {
            min-width: 300px;
            &::placeholder {
                color: #d3d3d3;
            }
            &::-webkit-input-placeholder { /* Edge */
                color: #d3d3d3;
            }
            &::-moz-placeholder {
                color: #d3d3d3;
            }
            &::-moz-placeholder { 
                color: #d3d3d3;
            }
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #d3d3d3;
            }            
        }
        &.validation-error {
          border-color: red;  
        }
    }

    .validation-message {
        color: red;
    }

}